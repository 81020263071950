<template>
  <div class="addCoupon">
    <el-breadcrumb class="top" separator="/">
      <el-breadcrumb-item>添加优惠券</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="120px">
      <el-form-item label="优惠券分类名称">
        <el-input
          v-model="formList.cateName"
          placeholder="请输入优惠券分类名称"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="onSave">保存</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
import { couponAddAPI} from "../../../api/coupon";
export default {
  name: "addCoupon",
  data() {
    return {
      formList: {
        cateId:0,
        cateName:''
      },
    };
  },
  created() {
      this.formList.cateId = this.$route.query.id
  },

  methods: {

    async onSave() {
        const {data} = await couponAddAPI(this.formList)
        if(data.code == 0){
            this.$message({
              type: "success",
              message: data.msg,
            });
            setTimeout(this.$router.go(-1),500);
        }
    },

  },
};
</script>
<style lang="less" scoped>
.addCoupon {
  .top {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/ .el-form {
    margin-top: 50px;
    margin-left: 20px;
    .lingqu {
      margin-left: 10px;
    }
    .col {
      display: inline-block;
      .el-form-item__content {
        .el-input {
          width: 250px !important;
        }
      }
    }
    .el-form-item {
      .el-form-item__label {
        width: 130px !important;
        padding: 0;
      }
      .el-form-item__content {
        .el-input {
          width: 600px;
        }
        .el-date-editor {
          width: 291px;
        }
      }
    }
  }
  .btn {
    margin-top: 50px;
    margin-left: 20px;
  }
  /deep/ .addHotel {
    .el-dialog {
      .el-dialog__body {
        .el-pagination {
          margin-top: 20px;
          float: right;
        }
        .add {
          margin-top: 20px;
        }
        .el-table {
          margin-top: 50px;
        }
        .search {
          span {
            margin: 0 20px;
          }
          .el-input {
            width: 300px;
          }
          .el-select {
            width: 300px !important;
          }
          .el-button {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>